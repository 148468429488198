<template xmlns:b-row="http://www.w3.org/1999/html">
  <div class="user-list-annotations">
    <b-card-actions
      v-if="!small"
      ref="cardActions"
      :action-collapse="false"
      :action-close="false"
      :action-refresh="true"
      @refresh="getAnnotations"
    >
      <b-row>
        <b-col
          ref="btnShowAnnotation"
          cols="12"
          class="text-right mb-3"
        >
          <b-button
            class="add-annotation"
            variant="primary"
            title="Alterar anotação"
            @click="showAnnotation()"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
              class="align-middle"
            /> Anotação
          </b-button>
        </b-col>
      </b-row>

      <transition name="fade">
        <div
          v-if="showingAnnotation"
          class="mb-3"
        >
          <validation-observer ref="rulesAnnotation">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Anotação"
                  label-for="annotation"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|min:10"
                  >
                    <b-form-textarea
                      id="annotation"
                      v-model="annotation.annotation"
                      rows="10"
                      :disabled="isSubmiting"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="userData.permission_type === 'success' || userData.permission_type === 'relationship' || userData.permission_type === 'root'"
                cols="4"
              >
                <b-form-group
                  label="Tipo de contato"
                  label-for="type"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="['root', 'success'].includes(userData.permission_type) ? '' : 'required|min:2'"
                  >
                    <b-form-select
                      id="type"
                      v-model="annotation.type"
                      :options="annotationTypes"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isSubmiting"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="userData.permission_type === 'success' || userData.permission_type === 'relationship' || userData.permission_type === 'root'"
                cols="4"
              >
                <b-form-group
                  label="Canal"
                  label-for="type"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="['root', 'success'].includes(userData.permission_type) ? '' : 'required|min:2'"
                  >
                    <b-form-select
                      id="channel"
                      v-model="annotation.channel"
                      :options="annotationChannels"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isSubmiting"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="userData.permission_type === 'success' || userData.permission_type === 'root'"
                cols="4"
              >
                <b-form-group
                  label="Viés"
                  label-for="type"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules=""
                  >
                    <b-form-select
                      id="bias"
                      v-model="annotation.bias"
                      :options="annotationBias"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isSubmiting"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="userData.permission_type === 'success' || userData.permission_type === 'relationship' || userData.permission_type === 'root'"
                cols="4"
              >
                <b-form-group
                  label="Atribuir a"
                  label-for="assign_to"
                >
                  <validation-provider
                    #default="{ errors }"
                    :rules="['root', 'success'].includes(userData.permission_type) ? '' : 'required|min:2'"
                  >
                    <b-form-select
                      id="assign_to"
                      v-model="annotation.assign_to"
                      :options="annotationAssignsTo"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isSubmiting"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                align="right"
                variant="secondary"
                class="mr-1"
                type="submit"
                :disabled="isSubmiting"
                @click.prevent="cancelAnnotation()"
              >
                Cancelar
              </b-button>

              <b-button
                align="right"
                variant="primary"
                type="submit"
                :disabled="isSubmiting"
                @click.prevent="saveAnnotation()"
              >
                Salvar
                <b-spinner
                  v-if="isSubmiting"
                  small
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </transition>

    </b-card-actions>
    <b-table
      :busy="isLoading"
      fixed
      :fields="result_fields"
      :items="results"
      sort-icon-left
      no-local-sorting
      :sort-by.sync="filtersValues.sortBy"
      :sort-desc.sync="filtersValues.sortDesc"
      @sort-changed="sortingChanged"
    >
      <template #table-busy>
        <div class="text-center table-spinner">
          <b-spinner label="Loading..." />
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #head(user_id)>
        Usuário Id
      </template>

      <template #head(name)>
        Nome
      </template>

      <template #head(actions)>
        <div class="text-center">
          <span>Ações</span>
        </div>
      </template>

      <template #head(created_at)>
        Criado em
      </template>

      <template #head(thrower)>
        Criado por
      </template>

      <template #head(annotation)>
        Descrição
      </template>

      <template #head(small)>
        Anotações
      </template>

      <template #cell(small)="row">
        <div v-if="row.item.is_email">
          <span>{{ row.item.created_at | date }}</span><br>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="row.item.thrower" /><br>
          <!-- eslint-enable vue/no-v-html -->
          <email-annotation :item="row.item" />
        </div>
        <div v-else>
          <span>{{ row.item.created_at | date }}</span><br>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="row.item.thrower" /><br>
          <span v-html="row.item.annotation" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </template>

      <template #cell(created_at)="row">
        {{ row.item.created_at | date }}
      </template>

      <template #cell(annotation)="row">
        <div v-if="row.item.is_email">
          <email-annotation :item="row.item" />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <span
          v-else
          style="white-space: pre-wrap;"
          v-html="row.item.annotation"
        />
      <!-- eslint-enable vue/no-v-html -->
      </template>

      <template #cell(actions)="row">
        <div class="text-nowrap text-center">
          <b-button
            v-if="['individual_email', 'manager_sent_payment_link', 'manager_sent_proposal'].includes(row.item.type)"
            size="sm"
            variant="primary"
            title="Ver e-mail"
            @click="showEmail(row.item.extra.email_id)"
          >
            <feather-icon
              icon="SendIcon"
              size="16"
              class="align-middle"
            />
          </b-button>
          <b-button
            v-else-if="canEdit(row.item)"
            class="btn-actions"
            variant="flat-primary"
            title="Alterar"
            @click="editAnnotation(row.item)"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle text-body"
            />
          </b-button>
        </div>
      </template>

    </b-table>

    <b-row>
      <b-col cols="12">
        <!-- pagination nav -->
        <b-pagination-nav
          v-model="currentPage"
          :number-of-pages="pages.last_page"
          :link-gen="linkGen"
          align="end"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-email"
      ref="modal-email"
      content-class="shadow"
      title="E-mail"
      size="lg"
      no-fade
      ok-only
      ok-title="Fechar"
    >
      <b-card-text>
        <div
          v-if="isLoadingEmail"
          class="text-center"
        >
          <b-spinner
            label="Carregando..."
          />
        </div>
        <div v-if="!isLoadingEmail">
          <p class="mb-1">
            <span class="font-weight-bold">Título</span> <br />
            {{ email.subject }}
          </p>

          <p>
            <span class="font-weight-bold">Anexo</span><br />
            {{ email.attachment_name }}
            <b-button
              variant="outline-primary"
              class="btn-icon"
              size="sm"
              @click="downloadAttachment"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </p>

          <p>
            <span class="font-weight-bold">Mensagem</span>
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <p v-html="email.message" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import UserService from '@/services/userService'
import {
  BRow,
  BFormGroup,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BFormTextarea,
  BPaginationNav,
  BModal,
  BCardText,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  roleSuccessAnnotationType,
  roleSuccessAnnotationAssignTo,
  annotationChannels,
  annotationBias,
  roleRelationshipAnnotationType,
  roleRelationshipAnnotationAssignTo,
} from '@/utils/options'
import EmailService from '@/services/emailService'
import { formatDateTimeDbToView } from '@/utils/helpers'
import downloadFile from '@/mixins/downloadFile'
import EmailAnnotation from './EmailAnnotation.vue'
import requestErrorMessage from '../../../mixins/requestErrorMessage'

export default {
  components: {
    BCardActions,
    BRow,
    BFormGroup,
    BCol,
    BButton,
    BFormTextarea,
    BFormSelect,
    BTable,
    BSpinner,
    BPaginationNav,
    BModal,
    BCardText,
    EmailAnnotation,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [requestErrorMessage, downloadFile],

  props: {
    userId: {
      type: Number,
      default: null,
    },
    managerId: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formatDateTimeDbToView,

      isSubmiting: false,
      isLoading: true,
      isLoadingEmail: false,
      emailService: null,
      email: {
        subject: '',
        message: '',
      },
      userData: {
        id: null,
        name: null,
        permission_type: null,
      },
      userService: null,
      initExecuted: false,
      showingAnnotation: false,
      pages: {
        last_page: 1,
      },
      currentPage: 1,
      results: [],
      annotation: {},

      filtersValues: {
        sortBy: 'id',
        sortDesc: true,
      },
      roleSuccessAnnotationType,
      roleSuccessAnnotationAssignTo,
      annotationChannels,
      annotationBias,
      roleRelationshipAnnotationAssignTo,
      roleRelationshipAnnotationType,
    }
  },

  computed: {
    result_fields() {
      if (this.small) {
        return [
          { key: 'small', sortable: false },
        ]
      }

      return [
        { key: 'created_at', sortable: false, thStyle: 'width: 200px' },
        { key: 'thrower', sortable: false, thStyle: 'width: 200px' },
        { key: 'annotation', sortable: false },
      ]
    },
    annotationTypes() {
      if (this.userData.permission_type === 'success') {
        return this.roleSuccessAnnotationType
      }

      if (this.userData.permission_type === 'relationship') {
        return this.roleRelationshipAnnotationType
      }

      if (this.userData.permission_type === 'root') {
        return [
          ...this.roleSuccessAnnotationType,
          ...this.roleRelationshipAnnotationType,
        ]
      }

      return []
    },

    annotationAssignsTo() {
      if (this.userData.permission_type === 'success') {
        return this.roleSuccessAnnotationAssignTo
      }

      if (this.userData.permission_type === 'relationship') {
        return this.roleRelationshipAnnotationAssignTo
      }

      if (this.userData.permission_type === 'root') {
        const ret = []
        const all = [
          ...this.roleSuccessAnnotationAssignTo,
          ...this.roleRelationshipAnnotationAssignTo,
        ]
        all.forEach(v => {
          if (ret.filter(f => f.value === v.value).length === 0) {
            ret.push(v)
          }
        })

        return ret
      }

      return []
    },
  },

  watch: {
    currentPage() {
      this.getAnnotations()
    },
  },

  created() {
    this.userService = new UserService()
    this.emailService = new EmailService()

    this.userData = JSON.parse(localStorage.getItem('userData'))

    this.getAnnotations()

    if (this.showActions()) {
      this.result_fields.push({ key: 'actions', sortable: false, thStyle: 'width: 150px' })
    }
  },

  methods: {
    downloadAttachment() {
      this.emailService.getUrlDownloadAttachment(this.email.attachment_name, this.email.attachment_path).then(response => {
        this.openModalToSaveFile(response, this.email.attachment_name)
      })
    },

    init() {
      if (!this.initExecuted) {
        this.getAnnotations()
        this.initExecuted = true
      }
    },

    linkGen() {
      return '#'
    },

    showActions() {
      return this.$can('edit', 'Journey') && !this.small
    },

    canEdit(item) {
      if (item.is_email) {
        return false
      }

      if (this.userData.permission_type === 'root') {
        return true
      }

      if (item.editable && this.$can('edit', 'Journey')) {
        return item.thrower_id === this.userData.id
      }

      return false
    },

    showAnnotation() {
      this.annotation = {
        id: 0,
        annotation: '',
        type: '',
        channel: '',
        bias: '',
        assign_to: '',
      }

      this.showingAnnotation = true
    },

    editAnnotation(item) {
      this.annotation = {
        ...item,
        type: item.type ?? '',
        channel: item.channel ?? '',
        bias: item.bias ?? '',
        assign_to: item.assign_to ?? '',
      }

      this.showingAnnotation = true

      this.$refs.btnShowAnnotation.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    },

    cancelAnnotation() {
      this.showingAnnotation = false
    },

    addAnnotation() {
      this.isSubmiting = true

      const data = {
        ...this.annotation,
        assign_to: this.annotation.assign_to === 'empty' ? '' : this.annotation.assign_to,
      }

      this.userService.addAnnotation(this.userId, data).then(response => {
        if (response.status === 200) {
          this.getAnnotations()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro!',
              text: 'Ops, algo deu errado!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }

        this.isSubmiting = false
        this.cancelAnnotation()
      }).catch(error => {
        this.isSubmiting = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: this.getResponseErrorMessage(error.response.data),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    updateAnnotation() {
      this.isSubmiting = true

      const data = {
        ...this.annotation,
        assign_to: this.annotation.assign_to === 'empty' ? '' : this.annotation.assign_to,
      }

      this.userService.updateAnnotation(this.annotation.id, data).then(response => {
        if (response.status === 200) {
          this.getAnnotations()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro!',
              text: 'Ops, algo deu errado!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }

        this.isSubmiting = false
        this.cancelAnnotation()
      }).catch(error => {
        this.isSubmiting = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: this.getResponseErrorMessage(error.response.data),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    postAnnotation() {
      if (this.annotation.id === 0) {
        this.addAnnotation()
      } else {
        this.updateAnnotation()
      }
    },

    saveAnnotation() {
      return this.$refs.rulesAnnotation.validate().then(success => {
        if (success) {
          if (this.managerId && this.userData.permission_type === 'sales' && this.managerId !== this.userData.id) {
            this.$swal({
              title: 'Este usuário já pertence a outro consultor',
              text: 'A observação será inserida, mas este usuário não será atribuído à sua carteira',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Inserir anotação',
              cancelButtonText: 'Cancelar',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary',
              },
              buttonsStyling: false,
            }).then(response => {
              if (response.isConfirmed) {
                this.postAnnotation()
              }
            })
          } else {
            this.postAnnotation()
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro de validação!',
              text: 'Preencha todos os campos corretamente',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },

    getAnnotations() {
      this.isLoading = true

      this.userService.getAndStoreManagers().then(managers => (
        this.userService.listAnnotations(this.userId, this.currentPage).then(response => {
          this.results = response.data.data.annotations.data.map(a => {
            let thrower = managers.find(m => m.id === a.account_manager_id) ? managers.find(m => m.id === a.account_manager_id).name : ''
            if (a.thrower) {
              thrower = a.thrower
            }

            return {
              ...a,
              assign_to: a.assign_to === null || a.assign_to === '' ? 'empty' : a.assign_to,
              thrower,
              thrower_id: a.account_manager_id,
              manager: null,
              extra: JSON.parse(a.extra),
            }
          })
          this.pages = { ...response.data.data.annotations, data: null }
          this.isLoading = false
          if (this.$refs.cardActions && this.$refs.cardActions.showLoading) {
            this.$refs.cardActions.showLoading = false
          }
        })
      ))
    },

    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
        this.getAnnotations()
      }
    },

    showEmail(id) {
      this.isLoadingEmail = true

      this.emailService.getEmail(id).then(response => {
        this.isLoadingEmail = false
        this.email = response.data.data.email
      })

      this.$refs['modal-email'].show()
    },
  },
}
</script>

<style lang="scss">
  .user-list-annotations{
    .b-table{
      td{
        padding: 0.8rem 1rem;
      }
    }

    .btn-actions{
      padding: 0px;
    }
  }
</style>

<style scoped>
  h4{
    margin-bottom: 20px;
  }

  .text-danger{
    min-height: 20px;
    display: block;
  }

  .card-body {
    padding: 0;
  }
</style>
